import icon1 from "images/Product/Campaign/icon-1.svg"
import icon2 from "images/Product/Campaign/icon-2.svg"
import { useFormatter } from "helpers/i18n"
import "./Recommentdation.scss"

export default function Recommentdation() {
  const { __ } = useFormatter()
  return (
    <>
      <div className="xaslCohnoT">
        <div className="rDRfHCHxXJ">
          <div className="wTSlispjcx">
            {__({
              defaultMessage:
                "Personalised Recommendation – AI/Machine Learning Data-Driven Approach",
            })}
          </div>
          <div className="rBAxnbCWtS">
            <div className="juLRYOAlGI">
              <div className="tvMeQUBDED">
                <img src={icon1} alt="" className="hjTuooQiES" />
              </div>
              <div className="kicAKpkeXW">
                <div className="ZMJCDFusSQ">
                  {__({
                    defaultMessage: "A. Next-Best-Offer Recommendation",
                  })}
                </div>
                <div className="sCSeiCAAJW">
                  {__({
                    defaultMessage:
                      "How do you drive revenue by recommending the most relevant products for the customer's next purchase?",
                  })}
                </div>
              </div>
            </div>
            <div className="juLRYOAlGI">
              <div className="tvMeQUBDED">
                <img src={icon2} alt="" className="hjTuooQiES" />
              </div>
              <div className="kicAKpkeXW">
                <div className="ZMJCDFusSQ">
                  {__({
                    defaultMessage: "B. Propensity to Purchase",
                  })}
                </div>
                <div className="sCSeiCAAJW">
                  {__({
                    defaultMessage:
                      "How do you grow the revenue of certain products by reaching customers with the highest propensity to purchase them?",
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
